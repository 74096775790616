<template>
  <div class="trade">
    <div class="panel-title">
      {{ $t("trade") }}
    </div>
    <div class="search-query-container">
      <div class="search-query">
        <el-form :model="searchForm" ref="searchForm" :rules="rules">
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('outTradeNo')" prop="outTradeNo">
                <el-input clearable v-model="searchForm.outTradeNo" :placeholder="$t('outTradNoHint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList()" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>
    </div>

    <div class="result-background">
      <div class="result-table">
        <el-table :data="tradeData" v-loading="loading" :row-class-name="rowClassName" @row-click="toDetail">
          <el-table-column prop="endTime" :label="$t('date')" min-width="200">
            <template slot-scope="scope">
              <div>
                {{ dateFormat(scope.row.endTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tradeRefvNo" :label="$t('tradNo')" min-width="200"></el-table-column>
          <el-table-column prop="tradeTypeDisplayName" :label="$t('tradePayKind')" width="220">
            <template slot-scope="scope">
              <div class="image-text-container">
                <img :src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName" />
                {{ scope.row.tradeTypeDisplayName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tradeState" :label="$t('tradeKind')" width="100">
            <template slot-scope="scope">
              {{ scope.row.tradeState === "REFUND" ? $t("refund") : $t("payment") }}
            </template>
          </el-table-column>
          <el-table-column prop="shopName" :label="$t('store')" width="150"></el-table-column>
          <el-table-column prop="deviceNo" :label="$t('terminalNo')" width="150"></el-table-column>
          <el-table-column prop="orderAmount" :label="$t('cashCount')" min-width="150">
            <template slot-scope="scope">{{ moneyFormat(scope.row.orderAmount) }} </template>
          </el-table-column>
          <el-table-column width="150" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("paymentInfo") }}</div>
        <div class="name-value" style="margin-bottom: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">{{ $t("payDateTime") }}</td>
              <td class="value">
                {{ dateFormat(detailInfo.endTime) }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("enterpriseCode") }}
              </td>
              <td class="value">
                {{ detailInfo.entId }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("storeCode") }}</td>
              <td class="value">
                {{ detailInfo.shopId }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("storeName") }}</td>
              <td class="value">
                {{ detailInfo.shopDisplay }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("terminal") }}</td>
              <td class="value">
                {{ detailInfo.deviceNo }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("paymentNo") }}</td>
              <td class="value">
                {{ detailInfo.tradeNo }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("outTradeNo") }}</td>
              <td class="value">
                {{
                  detailInfo.tradeCategory === 5 || detailInfo.tradeCategory === 6
                    ? detailInfo.orderNo
                    : detailInfo.outTradeNo
                }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("tradePayKind") }}
              </td>
              <td class="value image-text-container">
                <img :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)" :alt="detailInfo.tradeTypeDisplayName" />
                <span>{{ detailInfo.tradeTypeDisplayName }}</span>
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("tradeKind") }}
              </td>
              <td class="value">
                {{ $t("payment") }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">
                {{ moneyFormat(detailInfo.orderAmount) }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("printCount") }}
              </td>
              <td class="value">
                {{ detailInfo.payPrintTimes }}
              </td>
            </tr>
          </table>
        </div>

        <div class="setting-nav-title" v-if="detailInfo.refundRecordList && detailInfo.refundRecordList.length > 0">
          {{ $t("tradeRefundInfo") }}
        </div>

        <div
          class="name-value"
          v-for="item in detailInfo.refundRecordList"
          :key="item.recordId"
          style="margin-bottom: 20px;"
        >
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">
                {{ $t("refundDate") }}
              </td>
              <td class="value">
                {{ dateFormat(item.beginTime) }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("terminal") }}
              </td>
              <td class="value">
                {{ item.deviceId }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("refundNo") }}
              </td>
              <td class="value">
                {{ item.refundNo }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("tradeKind") }}</td>
              <td class="value">
                {{ $t("refund") }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">{{ moneyFormat(item.refundAmount) }}</td>
            </tr>
          </table>
        </div>
        <div
          class="explain"
          v-if="detailInfo.creditTradeStatus === 'S00000_35' || detailInfo.creditTradeStatus === 'S00000_33'"
        >
          <p style="color:#ec1919;">返金中です。しばらくお待ちください。</p>
        </div>
        <div v-permission="'Refund.Refund'">
          <div v-if="detailInfo.canRefund">
            <div class="explain">
              {{ $t("tradeRefundButtonInfo") }}
            </div>
            <el-button class="delete" style="margin-top: 20px;" @click="toRefund">
              {{ $t("tradeRefund") }}
            </el-button>
          </div>
        </div>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="refundDialog" :dialog-title="$t('tradeRefund')">
      <div class="explain" style="text-align: center;">
        <p>{{ $t("tradeRefundSettingInfo") }}</p>
        <p>{{ $t("refundDelayTips") }}</p>
      </div>

      <el-form label-position="top" class="refund-form" @submit.native.prevent>
        <el-form-item v-bind:label="$t('tradeRefundMoney')">
          <el-radio-group v-model="refundType" @change="handleRefundTypeChange" class="refund-radio">
            <el-radio label="all">{{ $t("cashAll") }}</el-radio>
            <el-radio label="part" v-if="canPartRefund">{{ $t("tradeCountSet") }}</el-radio>
          </el-radio-group>

          <el-input-number
            class="refund-part-input"
            v-if="canPartRefund"
            @change="refundAmountChange"
            v-model="refundAmount"
            :controls="false"
            :max="canRefundAmount"
            :min="0"
            :precision="refundAmountPrecision"
            :placeholder="$t('PleaseEnterRefundAmount')"
            :disabled="refundType != 'part'"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item :label="$t('refundDetail')">
          <table cellspacing="0" cellpadding="0" border="0" class="refund-table">
            <tr>
              <th>{{ $t("tradePaidCount") }}</th>
              <th>{{ $t("tradeRefundedCount") }}</th>
            </tr>

            <tr>
              <td>{{ moneyFormat(canRefundAmount) }}</td>
              <td>
                {{ refundType == "all" ? moneyFormat(canRefundAmount) : moneyFormat(refundAmount) }}
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <el-button class="cancel" :disabled="refundLoading" @click="dialogToCancel"> {{ $t("cancel") }} </el-button>
        <el-button
          class="confirm dialog-btn"
          type="primary"
          @click="refundSubmit"
          :loading="refundLoading"
          :disabled="refundLoading"
        >
          {{ $t("tradeChangeApply") }}
        </el-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";

import { recordListByOutTradeNo, recordDetail, sysRefund } from "api/trade";
import { isArray, cloneDeep, isUndefined } from "lodash";

import { moneyFormat, numFormat } from "utils";
import { dateFormat, diffMonths } from "utils/date";
import { getSessionStorage } from "@/utils/storage";
export default {
  mixins: [pagination, getPaymentLogos],
  data() {
    return {
      rules: {
        outTradeNo: [{ required: true, message: this.$t("outTradeNoIsNull"), trigger: "blur" }]
      },
      tradeData: [],
      loading: false,
      btnLoading: false,
      searchForm: {
        outTradeNo: ""
      },
      detailDrawer: false,
      detailLoading: false,
      detailInfo: {},
      refundDialog: false,
      canRefundAmount: 0,
      refundAmount: 0,
      refundType: "all",
      refundAmountPrecision: 0,
      canPartRefund: true,
      canEditRefundFee: false,
      refundDisabled: true,
      refundLoading: false
    };
  },
  methods: {
    dateFormat,
    numFormat,
    diffMonths,
    dialogToCancel() {
      this.refundDialog = false;
    },
    moneyFormat,
    getList() {
      this.$refs["searchForm"].validate(valid => {
        if (valid) {
          let that = this;
          this.loading = true;
          let data = JSON.parse(JSON.stringify(that.searchForm));
          recordListByOutTradeNo({
            ...data
          }).then(response => {
            this.loading = false;
            if (response.statusCode === 200 && response.resultStatus === "Success") {
              this.tradeData = response.result;
              this.total = response.totalCount;
            }
          });
        } else {
          return false;
        }
      });
    },

    resetSearchQuery() {
      this.searchForm = {
        outTradeNo: ""
      };
    },

    toDetail(row) {
      this.detailDrawer = true;
      this.detailInfo = {};
      let { outTradeNo, tradeType, orderNo } = row;

      this.detailLoading = true;
      recordDetail({ outTradeNo, tradeType, orderNo }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
          this.canRefundAmount = Number(this.detailInfo.orderAmount);

          if (isArray(this.detailInfo.refundRecordList)) {
            let allAmount = 0;

            this.detailInfo.refundRecordList.forEach(item => {
              allAmount += Number(item.refundAmount);
            });

            this.canRefundAmount = Number(this.detailInfo.orderAmount) - Number(allAmount);
          }
        }
      });
    },

    refundAmountChange(value) {
      isUndefined(value) ? (this.refundAmount = 0) : (this.refundAmount = value);
    },

    handleRefundTypeChange() {
      this.refundAmount = 0;
    },

    toRefund() {
      this.refundAmount = 0;
      this.refundType = "all";

      if (this.detailInfo.offlineCreditCard) {
        this.$message.warning(this.$t("unsupportedRefund"));
        return;
      }

      this.refundDialog = true;

      if (this.detailInfo.onlyAllRefund) {
        this.canPartRefund = false;
      } else {
        if (this.detailInfo.canPartialRefund) {
          this.canPartRefund = true;
        } else {
          this.canPartRefund = false;
        }
      }

      switch (getSessionStorage("currency")) {
        case "JPY":
          this.refundAmountPrecision = 0;
          break;
        default:
          this.refundAmountPrecision = 2;
          break;
      }
    },

    refundSubmit() {
      let data = cloneDeep(this.detailInfo);
      if (this.refundType === "all") {
        data.refundFee = this.canRefundAmount.toString();
      } else {
        if (this.refundAmount === 0) {
          this.$message.error(this.$t("refundFeeDataError"));
          return;
        }
        data.refundFee = this.refundAmount.toString();
      }
      this.refundLoading = true;
      sysRefund(data).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          let msg = this.$t("success");

          this.$message({
            showClose: true,
            message: msg,
            type: "success"
          });
          this.refundDialog = false;
          this.toDetail(this.detailInfo);
          this.getList(this.currentPage);
        } else {
          if (response.resultType === 4008) {
            let msg = this.$t("refundDelay");

            this.$message({
              showClose: true,
              message: msg,
              type: "success"
            });
            this.refundDialog = false;
            this.toDetail(this.detailInfo);
            this.getList(this.currentPage);
          }
        }
        this.refundLoading = false;
      });
    },
    rowClassName(data) {
      let { row } = data;
      if (row.tradeState === "REFUND") return "row-refund-color";
    }
  }
};
</script>
