import http from "../http";

export const tradeTypes = () => {
  return http({
    method: "get",
    url: `/api/trade/tradetypes`
  });
};
// 查询多企业多店铺支付方式列表
export const multiTradeTypes = params => {
  return http({
    method: "get",
    url: `/api/trade/multitradetypes`,
    params
  });
};

//查询psp trade types
export const psptradeTypes = () => {
  return http({
    method: "get",
    url: `/api/trade/psptradetypes`
  });
};
//查询point trade types
export const pointtradeTypes = params => {
  return http({
    method: "get",
    url: `/api/trade/pointtradetypes`,
    params
  });
};

export const entTradeTypes = () => {
  return http({
    method: "get",
    url: `/api/trade/enttradetypes`
  });
};

export const recordList = params => {
  return http({
    method: "get",
    url: `/api/trade/recordlist`,
    params
  });
};
// 查询多企业交易记录数据列表;
export const enterprisesRecordList = params => {
  return http({
    method: "get",
    url: `/api/trade/enterprisesrecordlist`,
    params
  });
};

export const recordListByOutTradeNo = params => {
  return http({
    method: "get",
    url: `/api/trade/recordlistbyouttradeno`,
    params
  });
};

export const recordDetail = params => {
  return http({
    method: "get",
    url: `/api/trade/recorddetail`,
    params
  });
};
// 多企业交易详情查询
export const enterprisesRecordDetail = params => {
  return http({
    method: "get",
    url: `/api/trade/enterprisesrecorddetail`,
    params
  });
};

export const refund = data => {
  return http({
    method: "post",
    url: `/api/trade/refund`,
    data
  });
};
//修改CPM订单备注
export const editRemark = data => {
  return http({
    method: "post",
    url: `/api/trade/editremark`,
    data
  });
};
//组合支付退款
export const orderRefund = data => {
  return http({
    method: "post",
    url: `/api/trade/orderrefund`,
    data
  });
};

export const sysRefund = data => {
  return http({
    method: "post",
    url: `/api/trade/sysrefund`,
    data
  });
};

//dpoint 211026
export const payRecordList = params => {
  return http({
    method: "get",
    url: `/api/trade/payrecordlist`,
    params
  });
};

export const payRecordDetail = params => {
  return http({
    method: "get",
    url: `/api/trade/payrecorddetail`,
    params
  });
};

export const downloadPayFile = params => {
  return http({
    method: "get",
    url: `/api/trade/payrecordsfile`,
    params,
    responseType: "blob"
  });
};

export const pointRecordList = params => {
  return http({
    method: "get",
    url: `/api/trade/pointrecordlist`,
    params
  });
};

export const pointRecordDetail = params => {
  return http({
    method: "get",
    url: `/api/trade/pointrecorddetail`,
    params
  });
};

export const downloadPointFile = params => {
  return http({
    method: "get",
    url: `/api/trade/pointrecordsfile`,
    params,
    responseType: "blob"
  });
};

export const orderRecordList = params => {
  return http({
    method: "get",
    url: `/api/trade/orderrecordlist`,
    params
  });
};

export const orderRecordDetail = params => {
  return http({
    method: "get",
    url: `/api/trade/orderrecorddetail`,
    params
  });
};

export const downloadOrderFile = params => {
  return http({
    method: "get",
    url: `/api/trade/orderrecordsfile`,
    params,
    responseType: "blob"
  });
};

//dpoint end

export const downloadTradeFile = params => {
  return http({
    method: "get",
    url: `/api/trade/recordsexcel`,
    params,
    responseType: "blob"
  });
};

export const downloadPointTradeFile = params => {
  return http({
    method: "get",
    url: `/api/trade/pointrecordsfile`,
    params,
    responseType: "blob"
  });
};
// 下载多企业交易记录;
export const downloadEnterprisesRecordsFile = params => {
  return http({
    method: "get",
    url: `/api/trade/enterprisesrecordsfile`,
    params,
    responseType: "blob"
  });
};

export const getPayBrand = params => {
  return http({
    method: "get",
    url: `/api/trade/oempsps`,
    params
  });
};
